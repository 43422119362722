import React from "react";
import ActivityListItem from "Components/activities/ActivityListItem";
import ActivityTitle from "Components/activities/ActivityTitle";
import SystemIcon from "Icons/SystemIcon";
import ContentLayout from "Components/ContentLayout";
import ListGroup from "Components/ListGroup";

class AllActivity extends React.Component {
  render() {
    return (
      <ContentLayout className="all-activity">
        <h4 id="today-heading">Today</h4>
        <ListGroup aria-labelledby="today-heading">
          <ActivityListItem
            icon={<SystemIcon color="#7996b1" size={28} />}
            title={
              <ActivityTitle
                name="Username"
                primaryText="modified_project_variable"
                primaryTitle="variable Name"
              />
            }
            state="complete"
            moment="11/06/17"
            result="failure"
          />
          <ActivityListItem
            icon={<SystemIcon color="#7996b1" size={28} />}
            title={
              <ActivityTitle
                name="Username"
                primaryText="modified_project_variable"
                primaryTitle="variable Name"
              />
            }
            state="success"
            moment="11/06/17"
            result="result"
          />
          <ActivityListItem
            icon={<SystemIcon color="#7996b1" size={28} />}
            title={
              <ActivityTitle
                name="Username"
                primaryText="modified_project_variable"
                primaryTitle="variable Name"
              />
            }
            state="success"
            moment="11/06/17"
            result="result"
          />
          <ActivityListItem
            icon={<SystemIcon color="#7996b1" size={28} />}
            title={
              <ActivityTitle
                name="Username"
                primaryText="modified_project_variable"
                primaryTitle="variable Name"
              />
            }
            state="success"
            moment="11/06/17"
            result="result"
          />
        </ListGroup>
        <h4 id="yesterday-heading">Yesterday</h4>
        <ListGroup aria-labelledby="yeasterday-heading">
          <ActivityListItem
            icon={<SystemIcon color="#7996b1" size={28} />}
            title={
              <ActivityTitle
                name="Username"
                primaryText="modified_project_variable"
                primaryTitle="variable Name"
              />
            }
            state="complete"
            moment="11/06/17"
            result="failure"
          />
          <ActivityListItem
            icon={<SystemIcon color="#7996b1" size={28} />}
            title={
              <ActivityTitle
                name="Username"
                primaryText="modified_project_variable"
                primaryTitle="variable Name"
              />
            }
            state="success"
            moment="11/06/17"
            result="result"
          />
          <ActivityListItem
            icon={<SystemIcon color="#7996b1" size={28} />}
            title={
              <ActivityTitle
                name="Username"
                primaryText="modified_project_variable"
                primaryTitle="variable Name"
              />
            }
            state="success"
            moment="11/06/17"
            result="result"
          />
          <ActivityListItem
            icon={<SystemIcon color="#7996b1" size={28} />}
            title={
              <ActivityTitle
                name="Username"
                primaryText="modified_project_variable"
                primaryTitle="variable Name"
              />
            }
            state="success"
            moment="11/06/17"
            result="result"
          />
        </ListGroup>
      </ContentLayout>
    );
  }
}

export default AllActivity;
